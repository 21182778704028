import {useState} from 'react';
import { InitPage } from './InitPage';
import { Chat } from './Chat';


export const SERVER_BASE_URL = "https://chat.owo69.me";

export function App() {

	var [user, _setUser] = useState(localStorage.user ? JSON.parse(localStorage.user) : {
		uuid: crypto.randomUUID(),
		secret: crypto.randomUUID()
	});
	function setUser(u) {
		_setUser(u);
		localStorage.user = JSON.stringify(u);
	}
	function updateUser(partial_user) {
		setUser({...user, ...partial_user});
	};

	var [theme, _setTheme] = useState(localStorage.theme || "");
	var setTheme = theme => { _setTheme(theme); localStorage.theme = theme;  }


	return <div className={"App"+((theme === "dark" || (!theme && window.matchMedia('(prefers-color-scheme: dark)').matches)) ? " dark" : "")}>
		<div className="App h-full dark:bg-black dark:text-white">
			{user.name ? <Chat user={user} updateUser={updateUser} theme={theme} setTheme={setTheme} /> : <InitPage updateUser={updateUser} />}
		</div>
	</div>
}






